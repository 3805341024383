<template>
  <div class="container">
    <app-bar
      title="Reward Detail"
      previousLink="/membership"
    />

    <div class="row">
      <div class="col-md-12">
        <div class="reward d-flex flex-column gap-3" :key="reward.kode">
          <div class="card card-reward bg-fuschia-100">
            <div class="card-body text-white h-100">
              <div class="d-flex align-items-end justify-content-between h-100">
                <div class="reward-group d-flex flex-column justify-content-start h-100">
                  <span class="fs-12 lh-12 fw-400">Tanggal Diambil: {{ date.toLocaleDateString('ID', options) }}</span>
                </div>
                <img
                  width="64"
                  :src="reward.icon"
                  alt="Reward"
                >
              </div>
            </div>
          </div>

          <div class="description d-flex flex-column gap-2">
            <div class="description-item d-flex align-items-center fw-500">
              <span class="description-label">Jenis</span>
              <div class="me-1">:</div>
              <span>{{ reward.jenis }}</span>
            </div>
            <div class="description-item d-flex align-items-center fw-500">
              <span class="description-label">Keterangan</span>
              <div class="me-1">:</div>
              <span>{{ reward.keterangan }}</span>
            </div>
          </div>

          <!-- Kode Voucher -->
          <div
            v-if="reward.status == 1 && reward.kode"
            class="voucher d-flex flex-column px-3 py-2 gap-2">
            <div class="voucher-label fs-12 fw-700">Kode Voucher</div>
            <div class="voucher-code d-flex align-items-center justify-content-between">
              <span id="voucher-code" class="fs-14 fw-500">{{ reward.kode }}</span>
              <button class="p-0 bg-transparent border-0" @click="copyCode()">
                <i class="ri-file-copy-line fs-18 color-neutral-600"></i>
              </button>
            </div>
          </div>

          <!-- button reedem -->
          <div v-if="reward.jenis?.toLowerCase().includes('free')" class="btn-group">
            <button
              v-if="reward.status == 0"
              class="btn-reedem w-100 rounded-pill py-2" @click="prepareClaimFree">
              <span class="text-white">CLAIM</span>
            </button>

            <router-link
              v-else-if="reward.status == 1 && reward.kode"
              :to="{
                name: 'ProfilOrderDetail',
                params: {
                  id_order: reward.kode
                }
              }"
              class="w-100"
            >
              <button class="btn-reedem w-100 rounded-pill py-2">
                <span class="text-white">PERIKSA</span>
              </button>
            </router-link>
          </div>
          <div v-else class="btn-group">
            <button
              v-if="reward.status == 0"
              class="btn-reedem w-100 rounded-pill py-2" @click="prepareClaim">
              <span class="text-white">CLAIM</span>
            </button>
          </div>

          <!-- Notif -->
          <div v-if="notification" class="notification bg-neutral-300 fw-500 fs-12 lh-14 px-3 py-2">
            Voucher code copied to clipboard
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import { claimReward } from '@/core/membership'
import { showNotif } from '@/core/utility'

export default {
  components: { AppBar },
  setup () {
    const notification = ref(false)
    const route = useRoute()
    const router = useRouter()
    const reward = ref({
      id_dt_member: route.query.id_dt_member,
      jenis: route.query.jenis,
      keterangan: route.query.keterangan,
      kode: route.query.kode,
      status: route.query.status,
      tgl_klaim: route.query.tgl_klaim,
      icon: route.query.icon
    })

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const date = new Date(reward.value.tgl_klaim)

    const copyCode = () => {
      const code = document.getElementById('voucher-code')
      navigator.clipboard.writeText(code.innerHTML)
      notification.value = true

      setTimeout(() => {
        notification.value = false
      }, 1000)
    }

    const prepareClaim = async () => {
      try {
        const result = await claimReward({
          id_dt_member: reward.value.id_dt_member,
          device: 'WEB'
        })

        if (result.status) {
          showNotif({
            status: 'success',
            message: 'Reward berhasil di claim / reedem'
          })

          await router.replace({
            name: 'DetailReward',
            params: { idMemberDt: reward.value.id_dt_member },
            query: {
              id_dt_member: reward.value.id_dt_member,
              jenis: reward.value.jenis,
              keterangan: reward.value.keterangan,
              kode: result.data.kode,
              status: 1, // fixed kan satu ketika selesai clain
              tgl_klaim: reward.value.tgl_klaim,
              tgl_reedem: reward.value.tgl_reedem,
              icon: reward.value.icon
            }
          })

          // set value yg baru
          reward.value = {
            id_dt_member: route.query.id_dt_member,
            jenis: route.query.jenis,
            keterangan: route.query.keterangan,
            kode: route.query.kode,
            status: route.query.status,
            tgl_klaim: route.query.tgl_klaim,
            icon: route.query.icon
          }
        } else {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        console.error('error claim reward', error)
        showNotif({
          status: 'error',
          message: error.response?.data?.message ?? error
        })
      }
    }

    const prepareClaimFree = async () => {
      router.push({
        name: 'SelectBranch',
        query: {
          redirectTo: 'membership',
          id_dt_member: reward.value.id_dt_member
        }
      })
    }

    return {
      reward,
      date,
      options,
      copyCode,
      notification,
      prepareClaim,
      prepareClaimFree
    }
  }
}
</script>

<style lang="scss" scoped>
.card-reward {
  border: none;
  border-radius: 12px;

  .card-body {
    padding: 14px;

    .reward {
      &-group {
        @media (max-width: 520px) {
          width: 15rem !important;
        }
      }

      &-description {
        @media (max-width: 520px) {
          font-size: 16px;
        }
      }
    }
  }
}

.description {
  &-label {
    min-width: 100px;
  }
}

.voucher {
  border: 1px solid #718096;
  border-radius: 8px;
}

.btn-group {
  .btn-reedem {
    background: black;
  }
}

.notification {
  border-radius: 8px;
  position: absolute;
  right: 3%;
  left: 3%;

  @media (min-width: 768px) {
    right: 15%;
    left: 15%;
  }
}
</style>
