import axios from '../plugins/axios'
import { reactive, ref } from 'vue'
import { user, token } from '@/core/auth'

export const totalPoint = ref(0)
export const levelPoint = ref([])

export const loading = reactive({
  totalPoint: false,
  levelPoint: false,
  claim: false
})

export const getTotalPoint = async () => {
  try {
    loading.totalPoint = true
    const { data } = await axios.get('member/poin', {
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    if (data.success) {
      totalPoint.value = data.poin
    }
  } catch (error) {
    console.error('error get membership total point = ', error)
    throw error
  } finally {
    loading.totalPoint = false
  }
}

export const getLevelPoint = async () => {
  try {
    loading.levelPoint = true
    const { data } = await axios.get('member/poin_level', {
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    console.log('data', data)

    if (data.success) {
      levelPoint.value = data.data
    }
  } catch (error) {
    console.error('error get membership level point = ', error)
    throw error
  } finally {
    loading.levelPoint = false
  }
}

export const claimReward = async (payload) => {
  try {
    loading.claim = true
    const { data } = await axios.post('member/klaim', payload, {
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    if (data.success) {
      return {
        status: true,
        message: 'Sukses',
        data: data.data
      }
    }
  } catch (error) {
    console.error('error get membership claim reward = ', error)
    throw error
  } finally {
    loading.claim = false
  }
}
